<template>
  <div class="verifyEmail-box">
    <h1 class="text-primary title-patternOne">
      Digite o e-mail do responsável
    </h1>
    <b-form @submit.prevent="verifyEmail()">
      <b-form-group>
        <b-form-input
          id="email"
          type="email"
          placeholder="E-mail*"
          size="lg"
          required
          v-model="formVerify.email"
          @keypress="preventWhiteSpace($event)"
        ></b-form-input>
      </b-form-group>
      <error-message :error="feedbackGeneralError" />
      <b-form-group>
        <b-button
          type="submit"
          variant="secondary"
          size="lg"
          :disabled="loadingButton"
          ><b-spinner large v-if="loadingButton"></b-spinner
          ><span v-else>Avançar</span></b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingButton: false,
    formVerify: {
      email: '',
    },
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    verifyEmail() {
      this.loadingButton = true;
      this.$onboardingService
        .verifyEmail(this.formVerify)
        .then((response) => {
          this.$store.state.emailVerified = this.formVerify.email;
          if (response.data.haveAccount)
            this.$router.push('/activate-by-account');
          else this.$router.push('/activate-without-account');
        })
        .catch((error) => {
          this.feedbackGeneralError = error;
          this.loadingButton = false;
        });
    },
    preventWhiteSpace(event) {
      let key = event.keyCode;
      if (key === 32) event.preventDefault();
    },
  },
  created() {
    localStorage.clear();

    if (this.$route.params.accessToken) {
      localStorage.setItem('access_token', this.$route.params.accessToken);
      if (this.$route.query.webgl) {
        localStorage.setItem('is_webgl', this.$route.query.webgl);
      } else {
        localStorage.setItem('is_webgl', false);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.verifyEmail-box {
  margin-top: 13rem;
}

form .btn {
  margin-top: 11.5rem;
}

h1.text-primary.title-patternOne {
  margin-bottom: 5rem;
}

@media (max-width: 768px) {
  .verifyEmail-box {
    margin-top: 0rem;
  }

  .container-md.text-center {
    margin-top: 10rem;
  }

  h1.text-primary.title-patternOne {
    margin-bottom: 3rem;
  }

  form .btn {
    margin-top: 7rem;
  }
}
</style>
